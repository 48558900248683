import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RestaurantModel } from "../models/interfaces/restaruant.model";
import { PaginationModel } from "../models/interfaces/pagination.model";
import { Observable } from "rxjs";
import { IRequestOptions } from "./utils.service";
import { ChefModel } from "../models/interfaces/chef.model";

@Injectable({
  providedIn: 'root'
})

export class ChefService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }


  /** GET RANDOM CHEFS **/
  getRandomChefs(pagination: PaginationModel): Observable<ChefModel[]> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_GET_RANDOM_CHEFS);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('Skip', pagination?.take * (pagination?.page - 1));
    queryParams = queryParams.append('Take', pagination?.take);
    queryParams = queryParams.append('Seed', pagination?.seed);
    let options = { params: queryParams, withCredentials:true} as IRequestOptions;
    return this.http.get<ChefModel[]>(endpoint, options);
  }

  /*-- Get Chef Details --*/
  getChefDetails(chef_id: number): Observable<ChefModel> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_GET_CHEF_DETAIL);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('id', chef_id);
    let options = { params: queryParams, withCredentials:true } as IRequestOptions;
    return this.http.get<ChefModel>(endpoint, options);
  }

    /*-- Add to favorites --*/
    addToFavorites(chef_id: number): Observable<any> {
      const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_ADD_TO_FAVORITES_CHEF);
      let options = { withCredentials:true } as IRequestOptions;
      return this.http.post<any>(endpoint,
          {
            entityId: chef_id
          },
          options
      );
    }


}
